/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import {
  Image,
  Billboard,
  CtaBar,
  useMapi,
  LeshenPhoneCTA,
  AvailabilityForm,
  VariableContent,
  List,
  ListItem,
  Stack,
  ModalWithState,
  Columns,
  Link,
  Column,
  SVGImage,
  LinkButton,
  SplitContent,
} from '@leshen/gatsby-theme-leshen'
import { Typography, Dropdown } from '@leshen/ui'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'
import styled from '@emotion/styled'

import getFullStateName from '../utils/getFullStateName'

const CityTemplate = ({ data }) => {
  const { requestId } = useMapi()

  const { State, Page_Path } = data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const momAndDaughterData = {
    ...data.momAndDaughter?.cloudinary[0]?.gatsbyImageData,
    context: data.momAndDaughter?.cloudinary[0]?.context,
  }

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: `Brightspeed is taking over CenturyLink Internet in ${State_Name}. Check our Brightspeed availability map and shop internet plans.`,
        title: `Brightspeed is taking over CenturyLink Internet in ${State_Name}.`,
        robots: 'index,follow',
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: 168999,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  const momAndSonData = {
    ...data.momAndSon?.cloudinary[0]?.gatsbyImageData,
    context: data.momAndSon?.cloudinary[0]?.context,
  }

  const customerServiceRepData = {
    ...data.customerServiceRep?.cloudinary[0]?.gatsbyImageData,
    context: data.customerServiceRep?.cloudinary[0]?.context,
  }

  const brightspeedLogoData = {
    ...data.brightspeedLogo?.cloudinary[0]?.gatsbyImageData,
    context: data.brightspeedLogo?.cloudinary[0]?.context,
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <ModalWithState showModal="true">
            <VariableContent
              image={<Image data={brightspeedLogoData} isImageCritical />}
              mainContent={<></>}
              style={{ marginBottom: '16px', paddingTop: '0px' }}
              alignMainContent="center"
              className="bsp-modal"
            >
              <Stack spacing="xxl">
                <Columns>
                  <Column borderColor="primary">
                    <Typography variant="h4">
                      Brightspeed is replacing CenturyLink in {State_Name}
                    </Typography>
                    <Typography>
                      Brightspeed is bringing new internet service to areas
                      formerly served by CenturyLink.
                    </Typography>
                    <List style={{ marginBottom: '16px' }}>
                      <ListItem>
                        <Typography>Easy-to-understand plans</Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>Reliable customer service</Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>Home phone and TV bundles</Typography>
                      </ListItem>
                    </List>
                  </Column>
                  <Column borderColor="primary">
                    <LinkButton
                      style={{ marginBottom: '16px' }}
                      variant="hero"
                      to={`https://www.brightspeedplans.com/internet?kbid=169000&clreqid=${requestId}`}
                    >
                      Shop Brightspeed Plans
                    </LinkButton>
                    <LeshenPhoneCTA variant="hero" color="primary">
                      Call to Order
                    </LeshenPhoneCTA>
                    <Typography style={{ marginBottom: '8px' }}>
                      Cities with Brightspeed Availability
                    </Typography>
                    <Dropdown
                      options={cityDropdownData}
                      onChange={(e) => navigate(e.value)}
                      placeholderText="Select City"
                    />
                    {cityDropdownData?.map((current) => (
                      <StyledLink href={current.value} key={current.value}>
                        {current.label}
                      </StyledLink>
                    ))}
                  </Column>
                </Columns>
              </Stack>
            </VariableContent>
          </ModalWithState>

          <Billboard
            customLink={GatsbyLink}
            image={<Image data={momAndDaughterData} isImageCritical />}
            mainContent={
              <>
                <Typography variant="h1">
                  Discover CenturyLink Internet in {State_Name}
                </Typography>
                {/* commenting out because this data doesnt exist for the bsp areas */}
                {/* <Typography variant="p">{State_Hero_Subheadline}</Typography> */}
                <AvailabilityForm
                  placeholderText="Enter ZIP Code"
                  buttonText="View Plans"
                  buttonColor="primary"
                />
              </>
            }
          />
          <CtaBar
            backgroundColor="secondary"
            textColor=""
            sticky
            leftBlock={<LeshenPhoneCTA color="white">Call Now</LeshenPhoneCTA>}
            middleBlock={
              <LinkButton color="white" to="/cart">
                Build Your Plan
              </LinkButton>
            }
          />
          <VariableContent
            customLink={GatsbyLink}
            mainContent={
              <>
                <Typography variant="h2">
                  Shop CenturyLink Internet plans in {State_Name}
                </Typography>
                <Typography variant="p">
                  {`Fuel your home with an internet connection capable of powering
                  multiple devices, high-frequency usage, and streaming
                  capabilities. But what's speed without the perks? CenturyLink
                  provides `}
                  <strong>
                    unlimited data, in-home WiFi, and non-stop customer service.
                  </strong>
                </Typography>
              </>
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="Light"
            image={
              <SVGImage
                svg={data.fiberHome.cloudinary[0].svg.code}
                presentationWidth={
                  data.fiberHome.cloudinary[0].svg.presentationWidth
                }
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink Internet plans right in your city
                </Typography>
                <Typography>
                  Let great speeds be your neighbor. CenturyLink Internet is
                  available in these cities within {State_Name}. Looking for
                  more than just internet? CenturyLink plans can be combined
                  with the following additional services:
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">Home phone</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">TV</Typography>
                    </Typography>
                  </ListItem>
                </List>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  CenturyLink home phone and TV provider options
                </Typography>
                <Typography>
                  Need more than one way to stay connected? Shop CenturyLink
                  home phone plans. Or combine home phone services with an
                  internet plan in a CenturyLink bundle—all on one bill.
                </Typography>
                <Typography>
                  Want entertainment? CenturyLink has teamed up with TV
                  providers to bring you the best selections possible. Ready for
                  the whole enchilada? CenturyLink makes it possible to
                  experience all three services when you combine home phone with
                  Internet and add TV from a TV provider teamed up with
                  CenturyLink.
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns leftAligned={false}>
                <Column
                  image={
                    <SVGImage
                      svg={data.cardsOne.cloudinary[0].svg.code}
                      presentationWidth={
                        data.cardsOne.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">
                    <Link to="/high-speed-internet">Internet</Link>
                  </Typography>
                </Column>
                <Column
                  image={
                    <SVGImage
                      svg={data.cardsTwo.cloudinary[0].svg.code}
                      presentationWidth={
                        data.cardsTwo.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">
                    <Link to="/home-phone">Home Phone</Link>
                  </Typography>
                </Column>
                <Column
                  image={
                    <SVGImage
                      svg={data.cardsThree.cloudinary[0].svg.code}
                      presentationWidth={
                        data.cardsThree.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">
                    <Link to="/tv">Entertainment</Link>
                  </Typography>
                </Column>
                <Column
                  image={
                    <SVGImage
                      svg={data.cardsFour.cloudinary[0].svg.code}
                      presentationWidth={
                        data.cardsFour.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">
                    <Link to="/bundles">Bundles</Link>
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="Light"
            image={<Image data={momAndSonData} isImageCritical />}
            mainContent={
              <>
                <Typography variant="h2">
                  Wireless internet: Nothing says welcome home like your WiFi
                  connection
                </Typography>
                <Typography>
                  Your internet plan comes with up-to-date modems and routers to
                  ensure your whole home is equipped with in-home WiFi service.
                  So no matter where you go in your home, your WiFi will follow.
                </Typography>
              </>
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            image={<Image data={customerServiceRepData} isImageCritical />}
            mainContent={
              <>
                <Typography variant="h2">
                  Around-the-clock tech support
                </Typography>
                <Typography>
                  Available when you need them, CenturyLink tech experts are
                  standing by to help you via chat or phone with problems that
                  may arise with your service or account.
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">Billing help</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">Service support</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        Equipment troubleshooting
                      </Typography>
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
          />
        </>
      }
    />
  )
}

const StyledLink = styled.a`
  display: none !important;
`

CityTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default CityTemplate

export const query = graphql`
  query CityTemplateQuery($pagePath: String, $stateAbbreviation: String) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerBrightspeedAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          City
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    cities: allDatasetManagerBrightspeedAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    momAndDaughter: contentfulMedia(
      contentful_id: { eq: "6Gk3Bsxc6jPMlis3BTxM7C" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    fiberHome: contentfulMedia(
      contentful_id: { eq: "4G2TwR45uNt7jnSAncrjue" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsOne: contentfulMedia(contentful_id: { eq: "24oT7HZOmADti1WnPWaDbk" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsTwo: contentfulMedia(contentful_id: { eq: "2czGv2FAEEV3iUWS7nsu6t" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsThree: contentfulMedia(
      contentful_id: { eq: "eyhAg9Jc5QH4QXmSpzbDq" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardsFour: contentfulMedia(
      contentful_id: { eq: "6qsSE9iPnFv9ZQAQdP26TT" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    momAndSon: contentfulMedia(
      contentful_id: { eq: "6YdptM0N2rhg51XBTaFfRJ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    customerServiceRep: contentfulMedia(
      contentful_id: { eq: "5yyjyak0EUIIgtCMYCUy4G" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    brightspeedLogo: contentfulMedia(
      contentful_id: { eq: "1ihX3tPHrNlaIVyyk5vHmQ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    footerDisclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-allrightsreserved" }
    ) {
      symbol
      text
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "082a6816-17e1-5fb3-9e89-55181e05011f" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "0ef79f94-dee9-5fbe-81be-670c6a1983eb" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
